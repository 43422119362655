import React from 'react'
import Seo from '@widgets/Seo'
import Divider from '@components/Divider'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import CardSection from '../../layout/CardSection'
import { Link } from 'gatsby'
import { Button, Container, Text } from 'theme-ui'
import Breadcrumbs from '../../components/Breadcrumbs/PageCrumbs'

export default () => {
  const title = 'Services'
  const styles = {
    flex: {
      display: 'flex',
    },
    running: {
      fontSize: `1.25em`,
    },
  }
  return (
    <>
      <Seo
        title='Private Label, Custom Formulation, Contract Manufacturing'
        description='Pacific Beauty offers private label, custom formulation, and contract manufacturing services for the skincare industry.'
      />
      <Divider space={3} />
      <Container variant='wide' sx={styles.flex}>
        <Breadcrumbs title={title} />
      </Container>
      <Divider space={2} />
      <Stack>
        <Main>
          <PageTitle header='Services' />
          <CardSection title='Private Label'>
            <Text sx={styles.running}>
              Launching skincare products doesn’t have to be an uphill struggle.
              With the help of Pacific Beauty, you will get your medical-grade
              skincare line up and running before you can say "clear skin." With
              our Private Label program you choose from our vast collection of
              stock products to incorporate into your brand.
            </Text>
            <Divider space={3} />
            <Button as={Link} to='/services/private-label/'>
              Read More
            </Button>
          </CardSection>
          <Divider />
          <CardSection title='Custom Formulation'>
            <Text sx={styles.running}>
              If you are thinking of launching your own branded skincare line,
              you might want to consider using our custom formulation services.
              Instead of settling for a final product you are not 100% happy
              with, a custom formulation allows you to go the extra mile and
              develop a product that is uniquely your own.
            </Text>
            <Divider space={3} />
            <Button as={Link} to='/services/custom-formulation/'>
              Read More
            </Button>
          </CardSection>
          <Divider />
          <CardSection title='Contract Manufacturing'>
            <Text sx={styles.running}>
              Already have a formula for a product? Let us be your partner in
              contract manufacturing. Our manufacturing facility is ISO22716
              certified, FDA registered, and we strictly adhere to all GMP
              standards. All products are manufactured in a 65,000 sq. ft.
              facility in Boca Raton, Florida, USA.
            </Text>
            <Divider space={3} />
            <Button as={Link} to='/services/contract-manufacturing/'>
              Read More
            </Button>
          </CardSection>
        </Main>
      </Stack>
    </>
  )
}
